import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import './App.css';
import { HeartFilledIcon, HeartIcon } from '@radix-ui/react-icons';

const Home = lazy(() => import('./components/Home'));
const WeddingDay = lazy(() => import('./components/WeddingDay'));
const FAQ = lazy(() => import('./components/FAQ'));
const Registry = lazy(() => import('./components/Registry'));
const ComingSoon = lazy(() => import('./components/ComingSoon'));

const Loading = () => (
  <div className="loading">
    <div className="spinner">
      <HeartFilledIcon className="heart-icon-2" color='red' width={50} height={50} />
      <div className='heart-fill' />
      <HeartIcon className="heart-icon" color='red' width={50} height={50} />
    </div>
    <div className="loading-text">
      <span>Loading...</span>
    </div>

  </div>
);

export const App = () => {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/schedule" element={<WeddingDay />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/registry" element={<Registry />} />
            <Route path="/accomodations" element={<ComingSoon />} />
            <Route path="/gallery" element={<ComingSoon />} />
            {/* Social Footer */}
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};