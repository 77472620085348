import React from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import './css/Navbar.css';

const NavBar = () => {

    const [mobile, setMobile] = React.useState(false);

    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 975) {
                setMobile(true);
            } else {
                setMobile(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <NavigationMenu.Root className="navbar">
            {/* Logo */}
            <div className="navbar-logo">
                <a href="/">Thomas & Allie</a>
            </div>

            {/* DESKTOP NAV: visible on larger screens */}
            <NavigationMenu.List className="navbar-links desktop-nav">
                <NavigationMenu.Item>
                    <NavigationMenu.Link href="/" className="navbar-link">Home</NavigationMenu.Link>
                </NavigationMenu.Item>
                <NavigationMenu.Item>
                    <NavigationMenu.Link href="/schedule" className="navbar-link">Wedding Day</NavigationMenu.Link>
                </NavigationMenu.Item>
                <NavigationMenu.Item>
                    <NavigationMenu.Link href="/accomodations" className="navbar-link">Accomodations</NavigationMenu.Link>
                </NavigationMenu.Item>
                <NavigationMenu.Item>
                    <NavigationMenu.Link href="/registry" className="navbar-link">Registry</NavigationMenu.Link>
                </NavigationMenu.Item>
                
                <NavigationMenu.Item>
                    <NavigationMenu.Link href="/faq" className="navbar-link">FAQ</NavigationMenu.Link>
                </NavigationMenu.Item>
            </NavigationMenu.List>

            {/* MOBILE NAV: hidden on larger screens, uses Trigger -> Content */}
            {mobile && <NavigationMenu.List className="mobile-nav">
                <NavigationMenu.Item style={{ position: 'relative' }}>
                    <NavigationMenu.Trigger className="hamburger-trigger" aria-label="Toggle menu">
                        <span className="hamburger-icon" />
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content className="mobile-menu">
                        <NavigationMenu.Link href="/" className="navbar-link">Home</NavigationMenu.Link>
                        <NavigationMenu.Link href="/schedule" className="navbar-link">Wedding Day</NavigationMenu.Link>
                        <NavigationMenu.Link href="/accomodations" className="navbar-link">Accomodations</NavigationMenu.Link>
                        <NavigationMenu.Link href="/registry" className="navbar-link">Registry</NavigationMenu.Link>
                        <NavigationMenu.Link href="/faq" className="navbar-link">FAQ</NavigationMenu.Link>
                    </NavigationMenu.Content>
                </NavigationMenu.Item>
            </NavigationMenu.List>}
        </NavigationMenu.Root>
    );
};

export default NavBar;
